import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results 12 months',
}

const questions: Array<Question> = [
  {
    question: 'Does it hurt?',
    answer:
      'A little bit scratchy, but not painful. Ultra-fine nano-needles are less ' +
      'painful than the harder blades used in microblading, but nanoblading takes ' +
      'longer. Your Ada Aesthetics practitioner will apply an anaesthetic cream ' +
      'beforehand to make you more comfortable. Nanoblading causes less trauma' +
      'to the skin, so it has a shorter recovery time; expect swelling and redness ' +
      'to subside within ten days for both treatments.\t',
  },
  {
    question: 'Which one is right for me?',
    answer:
      'At Ada Aesthetics, your treatment will depend on your skin type and hair. ' +
      'Bear in mind that nanoblading lasts longer because the pigment has more ' +
      'depth, so it is particularly suitable for darker skins. It is also a good ' +
      'choice for oilier skins because sebum breaks down the pigment more quickly. ' +
      'Sometimes we combine both techniques.\t',
  },
]

const Microblading: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Microblading and Nanoblading - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax
        titleName="Microblading and Nanoblading"
        customClass="page_title"
      />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                At Ada Aesthetics, we understand the power of strong,
                Instaworthy brows – no wonder they are an essential part of your
                grooming routine. We specialise in semi-permanent makeup and
                will help you choose between needles and blades.
              </p>
              <p>
                <b>Microblading</b><br/>
                Semi-permanent cosmetic brow tattooing makes your brows look
                fuller, more defined, and symmetrical: they frame your eyes and
                add definition to your whole face. Microblading has been the
                gold standard for many years and delivers pigment into the top
                layer of skin, the epidermis, for up to 12 months.
              </p>
              <p>
                <b>Nanoblading</b><br/>
                Despite the name, nanoblading uses needles, not blades. Whereas
                microblading improves the shape of the brow, nanoneedling is
                more precise. Ultra-sharp nano-needles create tiny strokes, as
                thin as individual eyebrow hairs, for more natural results. It
                is perfect for filling in thin eyebrows and defining arches.
                Lasts up to 3 years with touch-ups.
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default Microblading

export const pageQuery = graphql`
  query Microblading {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
